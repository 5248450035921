const PAGE_TITLE_DASHBOARD = "Dashboard"
const PAGE_TITLE_ADD_OPD = "Add New OPD"
const PAGE_TITLE_OPDs = "OPDs"
const PAGE_TITLE_DEPARTMENT_LIST = "Departments"
const PAGE_TITLE_DEPARTMENT_DETAILS = "Department Details"
const PAGE_TITLE_EFFECT_LIST = "Effects"
const PAGE_TITLE_EFFECT_DETAILS = "Effect Details"
const PAGE_TITLE_OPD_DETAILS = "OPD Details"
const PAGE_TITLE_PROFILE = "Profile"
const PAGE_TITLE_REPORT_DETAILS = "Report Details"
const PAGE_TITLE_REPORT_LIST = "Reports"
const PAGE_TITLE_SUBSCRIPTION = "Subscription"
const PAGE_TITLE_CITY_DETAILS = "City Details"
const PAGE_TITLE_ADD_USER = "User Details"
const PAGE_TITLE_ADD_MEDICINE = "Medicine Details"
const PAGE_TITLE_APPOINTMENT = "Appointments"
const PAGE_TITLE_APPOINTMENT_DETAILS = "Appointment Details"
const PAGE_TITLE_CHANGELOG = "Changelogs"
const PAGE_TITLE_CITY_LIST = "Citys"
const PAGE_TITLE_USER = "Users"
const PAGE_TITLE_FORGOT_PASSWORD = "Forgot Password"
const PAGE_TITLE_HELP = "Help"
const PAGE_TITLE_MEDICINE = "Medicines"
const PAGE_TITLE_PATIENT_DETAILS = "Patient Details"
const PAGE_TITLE_PATIENTS = "Patients"
const PAGE_TITLE_PRESCRIPTION = "Prescription"
const PAGE_TITLE_RESET_PASSWORD = "Reset Password"
const PAGE_TITLE_SETTINGS = "Settings"
const PAGE_TITLE_SIGN_IN = "Sign In"
const PAGE_TITLE_STATISTICS = "Statistics"
const LABEL_ARE_YOU_SURE = "Are You Sure"
const ALERT_MESSAGE_DELETE_PRE = "Are you sure you want to delete "
const DEPARTMENT = " department"
const REPORT = " report"
const EFFECT = " effect"
const CITY = " city"
const PAGE_CONTACT_US_REQUEST = " Contact Us Request"
const PAGE_TITLE_ROOMS_WARDS = "Rooms / Wards"
const ROOM = " room"
const WARD = " ward"
const PAGE_TITLE_IPD_LIST = "IPD List"
const PAGE_TITLE_IPD_DETAILS = "IPD Details"
const TREATMENT = " treatment"
const TREATMENT_DETAILS_NOT_FOUND = "Treatment Details Not Found"
const PAGE_TITLE_BILLINGS = "Billings"
const CONFIRM = "Confirm"
const MSG_CONFIRM_PAYEMNT_RECEIVED = "Please confirm you have received correct amount."
const PAGE_TITLE_SUBSCRIPTIONS = "Subscription Plans"
const PAGE_TITLE_SUBSCRIPTION_PLAN_DETAILS = "Subscription Plans Details"
const SUBSCRIPTION_PLAN = " subscription plan"
const PAGE_TITLE_CLIENTS = "clients"
const PAGE_TITLE_CLIENT_DETAILS = "Clients Details"
const PAGE_TITLE_CLIENT_USER_DETAILS = "Clients User Details"
const PAGE_TITLE_CLIENT_SUBSCRIPTIONS = "Clients Subscriptions"
const PAGE_TITLE_TRANSACTIONS = "Payment Transactions"
const PAGE_TITLE_CURRENCIES = "Currencies"
const PAGE_TITLE_INVENTORIES = "Inventories"
const CATEGORY = " category"
const SUPPLIER = " supplier"
const ITEM = " item"
const PAGE_TITLE_ITEM_DETAILS = "Item Details"
const PAGE_TITLE_STOCK_IN_OUT = "Stock In Out"
const TRANSACTIONS = " transactions"
const PAGE_TITLE_MAINTENANCE_REQUESTS = "Maintenance Requests"
const PAGE_TITLE_PURCHASE_ORDERS = "Purchase Orders"
const PURCHASE_ORDER = " purchase order"
const ROLE = " role"
const PAGE_TITLE_ROLE = "Roles"

export default {
  PAGE_TITLE_ADD_OPD: PAGE_TITLE_ADD_OPD,
  PAGE_TITLE_DASHBOARD: PAGE_TITLE_DASHBOARD,
  PAGE_TITLE_DEPARTMENT_LIST: PAGE_TITLE_DEPARTMENT_LIST,
  PAGE_TITLE_DEPARTMENT_DETAILS: PAGE_TITLE_DEPARTMENT_DETAILS,
  PAGE_TITLE_EFFECT_LIST: PAGE_TITLE_EFFECT_LIST,
  PAGE_TITLE_EFFECT_DETAILS: PAGE_TITLE_EFFECT_DETAILS,
  PAGE_TITLE_OPD_DETAILS: PAGE_TITLE_OPD_DETAILS,
  PAGE_TITLE_PROFILE: PAGE_TITLE_PROFILE,
  PAGE_TITLE_REPORT_DETAILS: PAGE_TITLE_REPORT_DETAILS,
  PAGE_TITLE_REPORT_LIST: PAGE_TITLE_REPORT_LIST,
  PAGE_TITLE_SUBSCRIPTION: PAGE_TITLE_SUBSCRIPTION,
  PAGE_TITLE_CITY_DETAILS: PAGE_TITLE_CITY_DETAILS,
  PAGE_TITLE_ADD_USER: PAGE_TITLE_ADD_USER,
  PAGE_TITLE_ADD_MEDICINE: PAGE_TITLE_ADD_MEDICINE,
  PAGE_TITLE_APPOINTMENT: PAGE_TITLE_APPOINTMENT,
  PAGE_TITLE_APPOINTMENT_DETAILS: PAGE_TITLE_APPOINTMENT_DETAILS,
  PAGE_TITLE_CHANGELOG: PAGE_TITLE_CHANGELOG,
  PAGE_TITLE_CITY_LIST: PAGE_TITLE_CITY_LIST,
  PAGE_TITLE_USER: PAGE_TITLE_USER,
  PAGE_TITLE_FORGOT_PASSWORD: PAGE_TITLE_FORGOT_PASSWORD,
  PAGE_TITLE_HELP: PAGE_TITLE_HELP,
  PAGE_TITLE_MEDICINE: PAGE_TITLE_MEDICINE,
  PAGE_TITLE_PATIENT_DETAILS: PAGE_TITLE_PATIENT_DETAILS,
  PAGE_TITLE_PATIENTS: PAGE_TITLE_PATIENTS,
  PAGE_TITLE_PRESCRIPTION: PAGE_TITLE_PRESCRIPTION,
  PAGE_TITLE_RESET_PASSWORD: PAGE_TITLE_RESET_PASSWORD,
  PAGE_TITLE_SETTINGS: PAGE_TITLE_SETTINGS,
  PAGE_TITLE_SIGN_IN: PAGE_TITLE_SIGN_IN,
  PAGE_TITLE_STATISTICS: PAGE_TITLE_STATISTICS,
  PAGE_TITLE_OPDs: PAGE_TITLE_OPDs,
  LABEL_ARE_YOU_SURE: LABEL_ARE_YOU_SURE,
  ALERT_MESSAGE_DELETE_PRE: ALERT_MESSAGE_DELETE_PRE,
  DEPARTMENT: DEPARTMENT,
  REPORT: REPORT,
  EFFECT: EFFECT,
  CITY: CITY,
  PAGE_CONTACT_US_REQUEST: PAGE_CONTACT_US_REQUEST,
  PAGE_TITLE_ROOMS_WARDS:PAGE_TITLE_ROOMS_WARDS,
  ROOM:ROOM,
  WARD:WARD,
  PAGE_TITLE_IPD_LIST: PAGE_TITLE_IPD_LIST,
  PAGE_TITLE_IPD_DETAILS: PAGE_TITLE_IPD_DETAILS,
  TREATMENT: TREATMENT,
  TREATMENT_DETAILS_NOT_FOUND: TREATMENT_DETAILS_NOT_FOUND,
  PAGE_TITLE_BILLINGS: PAGE_TITLE_BILLINGS,
  CONFIRM: CONFIRM,
  MSG_CONFIRM_PAYEMNT_RECEIVED: MSG_CONFIRM_PAYEMNT_RECEIVED,
  PAGE_TITLE_SUBSCRIPTIONS: PAGE_TITLE_SUBSCRIPTIONS,
  PAGE_TITLE_SUBSCRIPTION_PLAN_DETAILS: PAGE_TITLE_SUBSCRIPTION_PLAN_DETAILS,
  SUBSCRIPTION_PLAN: SUBSCRIPTION_PLAN,
  PAGE_TITLE_CLIENTS: PAGE_TITLE_CLIENTS,
  PAGE_TITLE_CLIENT_DETAILS: PAGE_TITLE_CLIENT_DETAILS,
  PAGE_TITLE_CLIENT_USER_DETAILS: PAGE_TITLE_CLIENT_USER_DETAILS,
  PAGE_TITLE_CLIENT_SUBSCRIPTIONS: PAGE_TITLE_CLIENT_SUBSCRIPTIONS,
  PAGE_TITLE_TRANSACTIONS: PAGE_TITLE_TRANSACTIONS,
  PAGE_TITLE_CURRENCIES: PAGE_TITLE_CURRENCIES,
  PAGE_TITLE_INVENTORIES: PAGE_TITLE_INVENTORIES,
  CATEGORY: CATEGORY,
  SUPPLIER: SUPPLIER,
  ITEM: ITEM,
  PAGE_TITLE_ITEM_DETAILS: PAGE_TITLE_ITEM_DETAILS,
  PAGE_TITLE_STOCK_IN_OUT: PAGE_TITLE_STOCK_IN_OUT,
  TRANSACTIONS: TRANSACTIONS,
  PAGE_TITLE_MAINTENANCE_REQUESTS: PAGE_TITLE_MAINTENANCE_REQUESTS,
  PAGE_TITLE_PURCHASE_ORDERS: PAGE_TITLE_PURCHASE_ORDERS,
  PAGE_TITLE_ROLE: PAGE_TITLE_ROLE,
  PURCHASE_ORDER: PURCHASE_ORDER,
  ROLE: ROLE,
}